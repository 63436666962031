import React, { useEffect } from "react";
import Traec from "traec";
import { connect } from "react-redux";

const MetaFields = props => {
  let { fields, setFields, companies, projects } = props;

  useEffect(() => {
    Traec.fetchRequiredFor({
      props,
      requiredFetches: [new Traec.Fetch("tracker", "list", { onlyTemplates: true }), new Traec.Fetch("company", "list")]
    });

    if (!fields.template_project && projects?.size) {
      setFields({ ...fields, template_project: projects.first().get("uid") });
    }

    if (!fields.supplier_holding_company && companies?.size) {
      setFields({ ...fields, supplier_holding_company: companies.first().get("uid") });
    }
  });

  let projectLinks = projects
    ?.valueSeq()
    .map((project, index) => (
      <option key={index} value={project.get("uid")}>
        {project.get("name")}
      </option>
    ))
    .toJS();
  let companyLinks = companies
    ?.valueSeq()
    .map((company, index) => (
      <option key={index} value={company.get("uid")}>
        {company.get("name")}
      </option>
    ))
    .toJS();

  const onChangeHandler = (e, type) => {
    setFields({ ...fields, [type]: e.target.value });
  };

  return (
    <div>
      <div className="form-group">
        <label>Project Template</label>
        <select className="form-control" onChange={e => onChangeHandler(e, "template_project")}>
          {projectLinks}
        </select>
      </div>
      <div className="form-group">
        <label>Supplier Holding Company</label>
        <select className="form-control" onChange={e => onChangeHandler(e, "supplier_holding_company")}>
          {companyLinks}
        </select>
      </div>
    </div>
  );
};

const mapStatToProps = (state, ownProps) => {
  let companies = state.getInPath(`entities.companies.byId`);
  companies = companies
    .filter(company => company.get("name") && company.get("parentid") === null)
    .filter(company => company.getInPath("meta_json.isHolding"));

  let trackers = state.getInPath(`entities.trackers.byId`);
  let projects = trackers
    ?.filter(tracker => tracker.get("is_public") && tracker.get("is_template"))
    .map(tracker => tracker.get("project"));

  return {
    companies,
    projects
  };
};
export default connect(mapStatToProps)(MetaFields);
