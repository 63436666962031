import React, { useState, useEffect } from "react";
import { ErrorBoundary } from "traec-react/errors/handleError";
import Traec from "traec";
import { connect } from "react-redux";
import { BSCard, BSBtnDropdown } from "traec-react/utils/bootstrap";
import ProjectInviteForm from "./projectInvite";
import { alertSuccess } from "traec-react/utils/sweetalert";

const getSiteInviteFetch = companyId => {
  return new Traec.Fetch(
    "company_dispatch",
    "post",
    { companyId },
    {
      preDispatchHook: action => {
        action.fetchParams.body = { type: "SITE_INVITE_LIST" };
        action.stateParams.stateSetFunc = (state, action) => {
          console.log("Got response data from SITE_INVITE_LIST", action);
          return state.setInPath(`companyObjects.byId.${companyId}.siteInvites`, action.payload?.payload);
        };
        console.log("Dispatching action for SITE_INVITE_LIST", action);
        return action;
      }
    }
  );
};

const deleteSiteInvite = invite => {
  let fetch = new Traec.Fetch("tenant_admin_dispatch", "post");
  fetch.updateFetchParams({
    body: {
      type: "DELETE_SITE_INVITE",
      payload: {
        company_id: invite.get("object_id"),
        invite_id: invite.get("uid")
      }
    },
    postSuccessHook: data => {
      console.log("GOT RESPINSE", data);
      //location.reload()
    }
  });
  fetch.dispatch();
};

const sendSiteInviteReminders = companyId => {
  let fetch = new Traec.Fetch("company_dispatch", "post", { companyId });
  fetch.updateFetchParams({
    body: {
      type: "SITE_INVITE_REMIND",
      payload: { status: "outstanding" }
    },
    postSuccessHook: data => {
      let success = data.status == "success";
      alertSuccess({
        iconType: success ? "success" : "error",
        text: success
          ? `Reminder emails have been queued`
          : `There was an error sending invite reminders.  Contact support if the problem persists.`,
        onConfirm: () => {
          location.reload();
        }
      });
    }
  });
  fetch.dispatch();
};

function SiteInviteRow({ invite, showAdmin }) {
  return (
    <div className="row">
      <div className="col-sm-7">{invite.get("email")}</div>
      <div className="col-sm-4">{invite.get("status")}</div>
      <div className="col-sm-1">
        {showAdmin ? (
          <BSBtnDropdown
            links={[
              {
                name: "Delete",
                onClick: e => {
                  deleteSiteInvite(invite);
                }
              }
            ]}
          />
        ) : null}
      </div>
    </div>
  );
}

function SiteInviteList({ invites, showAdmin }) {
  if (!invites) {
    return null;
  }
  return invites.map((invite, i) => <SiteInviteRow key={i} invite={invite} showAdmin={showAdmin} />);
}

const ReportingMembers = props => {
  let { companyId, siteInvites, user } = props;

  // For storing fetchedUrls for the component
  let [state, setState] = useState({});

  // Call the fetchRequired bound to this props and state
  useEffect(() => {
    Traec.fetchRequiredFor({
      props,
      state,
      setState,
      requiredFetches: [getSiteInviteFetch(companyId)]
    });
  });

  return (
    <ErrorBoundary>
      <BSCard
        title="Site Invites to Reporters"
        body={<SiteInviteList invites={siteInvites} showAdmin={user.get("is_tenant_superuser")} />}
        button={
          <div className="float-right">
            <div className="btn btn-primary" onClick={() => $(`#reporter_invite`).modal("show")}>
              Invite Reporters
            </div>
            <BSBtnDropdown
              floatStyle="ml-2"
              links={[
                {
                  name: "Send reminders to outstanding",
                  onClick: () => {
                    sendSiteInviteReminders(companyId);
                  }
                }
              ]}
            />
          </div>
        }
      />
      <ProjectInviteForm companyId={companyId} />
    </ErrorBoundary>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { companyId } = Traec.utils.getFullIds(state, ownProps);

  let siteInvites = state
    .getInPath(`entities.companyObjects.byId.${companyId}.siteInvites`)
    ?.sortBy(i => i.get("status"));

  let user = state.getInPath("auth.user");

  return { companyId, siteInvites, user };
};

export default connect(mapStateToProps)(ReportingMembers);
