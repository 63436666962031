import React from "react";
import { Editor } from "@tinymce/tinymce-react";
import { BSBtn } from "traec-react/utils/bootstrap";
import Octicon from "react-octicon";

const tinyMCEConfig = {
  menubar: false,
  statusbar: false,
  content_css: "/static/bootstrap/css/bootstrap.css",
  plugins: "autolink link image lists print preview",
  block_formats: "Paragraph=p;Header 3=h3;Header 4=h4;Header 5=h5;",
  toolbar:
    "bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | sup sub | formatselect | removeformat"
};

export const TextField = props => {
  let { title, configDict, type, handleChange } = props;

  title = title || type.charAt(0).toUpperCase() + type.slice(1);
  let enabled = configDict.get(`include_${type}`);
  let initValue = configDict.get(type) || `<p>Write a ${type} for this section of the report</p>`;

  let editor = enabled ? (
    <Editor
      initialValue={initValue}
      init={{ ...tinyMCEConfig, height: 200 }}
      onEditorChange={e => handleChange(e, type)}
    />
  ) : null;

  let is_hidden = configDict.get(`${type}_hidden`);

  return (
    <div className="pt-3 mt-3">
      <h3>
        <span>
          {title}
          {enabled ? (
            <Octicon
              name={is_hidden ? "chevron-right" : "chevron-down"}
              className="ml-2"
              onClick={() => handleChange(!is_hidden, `${type}_hidden`)}
            />
          ) : null}
        </span>

        <BSBtn
          text={enabled ? "Exclude" : "Include"}
          extra_className={enabled ? "border-success bg-sucess" : "border-secondary bg-secondary"}
          onClick={() => handleChange(!enabled, `include_${type}`)}
        />
      </h3>
      {is_hidden ? null : editor}
    </div>
  );
};
