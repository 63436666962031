import React, { useState } from "react";
import { ErrorBoundary } from "traec-react/errors/handleError";
import Traec from "traec";
import { connect } from "react-redux";
import { BSModal } from "traec-react/utils/bootstrap";
import { confirmDelete } from "traec-react/utils/sweetalert";
import { AddRowForm, TableHeaders, TableRow } from "../../project/report/reportDocumentForm";
import Papa from "papaparse";
import MetaFields from "./metaFields";
import { alertSuccess } from "traec-react/utils/sweetalert";

const saveData = (e, props) => {
  let { data, setPending, companyId, template_project_id, supplier_holding_company } = props;
  setPending(true);
  let fetch = new Traec.Fetch("tenant_admin_dispatch", "post");
  fetch.updateFetchParams({
    preFetchHook: _data => {
      return {
        type: "SEND_PROJECT_INVITES",
        payload: {
          company_id: companyId,
          invites: data.toJS(),
          template_project_id,
          supplier_holding_company
        }
      };
    },
    postSuccessHook: _data => {
      setPending(false);
      $(`#reporter_invite`).modal("hide");
      alertSuccess({
        text: `Your bulk invite request has been received.  
                We will begin queuing and inviting members now.
                You will be notified via email of the success/failure of the email invites.`
      });
    },
    postFailureHook: _data => {
      setPending(false);
    }
  });
  return fetch.dispatch();
};

function UploadFromCSVButton(props) {
  let { data, setData } = props;
  return (
    <button
      className="btn btn-sm btn-primary pt-0 pb-0 ml-1 mr-1 mb-2"
      onClick={e => {
        e.preventDefault();
        $("#file-selector").on("change", e => {
          Papa.parse(e.target.files[0], {
            complete: results => {
              let headers = results.data[0];
              //console.log("FOUND CSV HEADERS", headers)
              let newData = results.data
                .slice(1)
                .map(row => headers.reduce((acc, cur, i) => Object.assign(acc, { [cur]: row[i] }), {}));
              //console.log("GOT CSV DATA", newData)
              if (newData[newData.length - 1] && !newData[newData.length - 1].Email) {
                newData.pop();
              }
              setData(data.concat(Traec.Im.fromJS(newData)));
            }
          });
        });
        $("#file-selector").trigger("click");
      }}
    >
      Upload from CSV
    </button>
  );
}

function SendInvitesButton(props) {
  let { pending, metaFields } = props;
  let { template_project: template_project_id, supplier_holding_company } = metaFields || {};
  return (
    <button
      disabled={pending}
      className="btn btn-sm btn-primary pt-0 pb-0 ml-1 mr-1 mb-2 float-right"
      onClick={e => saveData(e, { ...props, template_project_id, supplier_holding_company })}
    >
      {pending ? <div className="spinner-border spinner-border-sm text-light" role="status" /> : "Send Invites"}
    </button>
  );
}

const ProjectInviteForm = props => {
  let { errors, companyId } = props;
  const [pending, setPending] = useState(false);
  const [fields, setFields] = useState(Traec.Im.fromJS([{ header: "Email" }, { header: "Company Name" }]));
  const [metaFields, setMetaFields] = useState({ template_project: null, supplier_holding_company: null });
  const [data, setData] = useState(Traec.Im.fromJS([]));

  return (
    <ErrorBoundary>
      <BSModal
        id={"reporter_invite"}
        title="Invite Reporting Members"
        body={
          <React.Fragment>
            <div style={{ clear: "both" }} />
            <div className="row">
              <div className="col">
                <UploadFromCSVButton data={data} setData={setData} />
              </div>

              <div className="col">
                <SendInvitesButton
                  companyId={companyId}
                  data={data}
                  metaFields={metaFields}
                  pending={pending}
                  setPending={setPending}
                />
              </div>
            </div>
            <input style={{ display: "none" }} type="file" id="file-selector" />
            <Errors errors={errors} />
            <hr className="mt-1 mb-1" />
            <MetaFields fields={metaFields} setFields={setMetaFields} />
            <InviteTable fields={fields} data={data} setData={setData} errors={errors} />
            <hr />
          </React.Fragment>
        }
      />
    </ErrorBoundary>
  );
};

const mapStateToProps = (state, ownProps) => {
  const errors = state.getInPath("entities.dispatch.errors.SEND_PROJECT_INVITES.payload");

  return { errors };
};

export default connect(mapStateToProps)(ProjectInviteForm);

const InviteTable = props => {
  let { fields, data, setData, errors } = props;

  let initNewData = fields.reduce((acc, cur) => acc.set(cur.get("header"), ""), Traec.Im.Map());
  let [newData, setNewData] = useState(initNewData);

  let rows = data.map((d, i) => (
    <TableRow
      key={i}
      index={i}
      fields={fields}
      errors={errors && Traec.Im.isMap(errors) ? errors.get(i.toString()) : null}
      data={d}
      setData={setData}
      removeHandler={() => {
        confirmDelete({
          text: `Are you sure you want to remove this row?`,
          onConfirm: () => {
            setData(data.delete(i));
          }
        });
      }}
      updateHandler={rowData => {
        setData(data.set(i, rowData));
      }}
      copyHandler={rowData => {
        setData(data.insert(i, rowData));
      }}
    />
  ));
  if (errors) {
    rows = rows.sortBy((row, index) => errors.get(index.toString()));
  }

  return (
    <div className="scrollTopContainer m-0 p-0" style={{ overflowX: "auto" }}>
      <table width="100%" className="table table-sm scrollTopContent m-0 p-0">
        <thead>
          <TableHeaders fields={fields} />
        </thead>
        <tbody>
          <AddRowForm
            fields={fields}
            values={newData}
            onChangeHandler={e => {
              setNewData(newData.merge({ [e.target.name]: e.target.value }));
            }}
            addHandler={e => {
              setData(data.unshift(newData));
              setNewData(initNewData);
            }}
          />
          {rows}
        </tbody>
      </table>
    </div>
  );
};

const Errors = props => {
  let { errors } = props;
  if (!errors || !errors.size) {
    return null;
  }

  if (Traec.Im.isList(errors)) {
    return (
      <div className="text-danger mb-2">
        <div>Could not send project invitations.</div>
        <div>{errors.toJS()}</div>
      </div>
    );
  }

  return <div className="text-danger mb-2">Could not send project invitations. See errors below</div>;
};
