import React, { useEffect } from "react";
import Traec from "traec";
import ReportingMembers from "./reportingMembers";
import CompanyMembers from "traec-react/members/companyMembers";
import { getCompanyPermissions } from "traec/utils/permissions/company";
import { connect } from "react-redux";

function CompanyMemberPage(props) {
  let { _companyId } = props;

  useEffect(() => {
    Traec.fetchRequiredFor({
      props,
      requiredFetches: [new Traec.Fetch("company", "read")]
    });
  });

  return <CompanyMembers {...props}>{<ReportingMembers _companyId={_companyId} />}</CompanyMembers>;
}

const mapStateToProps = (state, ownProps) => {
  let { _companyId } = ownProps.match.params;
  let { companyId } = Traec.utils.getFullIds(state, ownProps.match.params);
  companyId = companyId || _companyId;

  let company = state.getInPath(`entities.companies.byId.${companyId}`);
  let userPermissions = getCompanyPermissions(state, companyId);

  return { companyId, company };
};

export default connect(mapStateToProps)(CompanyMemberPage);
